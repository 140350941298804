/* eslint-disable react-hooks/exhaustive-deps */
import { Contract } from '@ethersproject/contracts';
import { formatEther, formatUnits, parseEther } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Swal from 'sweetalert2';
import Button from '../../Components/Button';
import contract from '../../Configs/Contract';
// import { toast } from "react-toastify";
import UseAuth from '../../Contexts/Auth/useAuth';
import { logHistory, getMarketPrice, buyNftTransaction } from '../../lib/NFT';
import Model from '../Model';
import styles from './styles.module.scss';
import blockChainData from '../../Hooks/blockChainData';
import getChainID from '../../Hooks/getChainID';
import SwitchNetwork from '../../Hooks/SwitchNetwork';
import UseUtil from '../../Contexts/Util/useUtil';
import Script from 'next/script';
import UseUser from '../../Contexts/User/useUser';
import {getUserWalletBalance} from '../../lib/User'

const PurchaseNftModel = (props) => {
  const { hideTransactionPopup, showTransactionPopup } = UseUtil();
  const currency = props.purchaseData.currency;
  const blockchain = props.purchaseData.blockchain;
  const router = useRouter();
  const [checked, setChecked] = React.useState(false);
  const [showErrorMsg, setShowErrorMessage] = React.useState(false);
  const [currentBalance, setCurrentBalance] = React.useState(0);
  const [onloading, setLoading] = React.useState(false);
  const { authState } = UseAuth();
  const context = useWeb3React();
  const { library, account, chainId } = context;
  const chainIDOfNFT = getChainID(currency);
  const [contractAddress, setContractAddress] = React.useState();
  const [contractABIKey, setContractABIKey] = React.useState();
  const [marketPriceData, setMarketPriceData] = React.useState(null);
  const [updateGems, setUpdateGems] = React.useState(null);
  const [walletDetail, setWalletDetail] = React.useState({})
  const { userState } = UseUser();

  useEffect(() => {
    setShowErrorMessage(false);
    setChecked(false);
  }, [props]);

  useEffect(() => {
    if (userState) {
      setUpdateGems(userState?.userProfileData?.gems);
    }
  }, [userState]);

  React.useEffect(() => {
    if (chainIDOfNFT) {
      let getBlockChainDataValue = blockChainData(chainIDOfNFT);
      setContractAddress(getBlockChainDataValue.contractAddress);
      setContractABIKey(getBlockChainDataValue.contractABIKey);
    }
  }, [chainIDOfNFT]);

  let nftPrice = 0;
  let serviceFee = (parseFloat(nftPrice) * parseFloat(2.5)) / 100;
  let totalPrice = parseFloat(nftPrice) + parseFloat(serviceFee);

  if (props?.purchaseData?.metadata?.price !== undefined) {
    nftPrice = props.purchaseData.metadata.price;
    serviceFee = (parseFloat(nftPrice) * parseFloat(2.5)) / 100;
    totalPrice = parseFloat(nftPrice) + parseFloat(serviceFee);
  }

  React.useEffect(async () => {
    if (props.visible === true && marketPriceData == null) {
      let marketPriceData = await getMarketPrice();
      setMarketPriceData(marketPriceData.data.data);
    }
  }, [props.visible]);

  React.useEffect(() => {
    if (
      authState?.isAuthenticated &&
      library &&
      userState?.userProfileData?.wallet_type !== "custom"
    ) {
      (async () => {
        let bal = await library.getBalance(account);
        setCurrentBalance(formatEther(bal).substring(0, 7));
      })();
    } else if (
      authState?.isAuthenticated &&
      userState?.userProfileData?.wallet_type === "custom"
    ) {
      (async () => {
        const req = {
          address: userState?.userProfileData?.public_address,
        };
        const response = await getUserWalletBalance(req);
        if (response?.status === 200) {
          setWalletDetail(response?.data?.data?.etherium_balance);
          setCurrentBalance(response?.data?.data?.polygon_balance);
        }
      })();
    }
  }, [authState?.isAuthenticated || library, userState?.userProfileData]);

  const getUSDPrice = (amount) => {
    if (amount !== '' && marketPriceData !== null) {
      let ethMarketPrice = marketPriceData.ETH;
      let maticMatketPrice = marketPriceData.MATIC;
      let priceToCalculate =
        props.purchaseData.currency === 'MATIC'
          ? maticMatketPrice
          : ethMarketPrice;
      let convertedUsdPrice = parseFloat(amount) * parseFloat(priceToCalculate);
      convertedUsdPrice = parseFloat(convertedUsdPrice).toFixed(2);
      return convertedUsdPrice;
    } else {
      return 0;
    }
  };

  const onPurchase = async () => {
    if (checked === false) {
      setShowErrorMessage(true);
      return true;
    }
    if (userState?.userProfileData?.wallet_type !== 'custom') {
    let requiredBlockChain = currency === 'MATIC' ? 'polygon' : 'ethereum';
    let requiredChainID =
      currency === 'MATIC'
        ? process.env.NEXT_PUBLIC_POLYGON_CHAIN_ID
        : process.env.NEXT_PUBLIC_CHAIN_ID;
    if (chainId !== requiredChainID) {
      props.onHide();
      setChecked(false);
    }
    let isValidBlockChain = await SwitchNetwork(chainId, requiredBlockChain);
    if (isValidBlockChain) {
      if (checked === false) {
        setShowErrorMessage(true);
      } else {
        try {
          setShowErrorMessage(false);
          setLoading(true);
          showTransactionPopup('NFT purchase in progress');

          let useracc = account;
          let mycon = new Contract(
            contract[contractAddress],
            contract[contractABIKey],
            library.getSigner()
          );
          let fee = await mycon.serviceFee();
          let price = props.purchaseData.metadata.price;
          let serviceFee = formatUnits(fee, 0);
          let servicePercentage =
            (parseFloat(price) * parseFloat(serviceFee)) / 1000;
          let finalValue = parseFloat(price) + parseFloat(servicePercentage);
          let finalValueFormat = parseFloat(finalValue).toFixed(9);
          let value = parseEther(finalValueFormat.toString());
          let tx = await mycon
            .buy(
              props.purchaseData.owned_by.public_address,
              useracc,
              props.purchaseData.token,
              { value, gasLimit : 300000 }
            )
            .then(async (data) => {
              const receipt = await data.wait(1);
              if (receipt) {
                props.onHide();
                setLoading(false);
                setChecked(false);
                hideTransactionPopup();
              }
              let logObj = {};
              logObj.operation = 'BOUGHT';
              logObj.token_ids = [props.purchaseData.token];
              logObj.tx_hash = receipt.transactionHash;
              logObj.blockchain = blockchain;
              let logresp = await logHistory(logObj);

              const script = `
            rdt('track', 'Purchase', {
              "currency": ${currency},
              "itemCount": 1,
              "transactionId": ${receipt.transactionHash},
              "value": ${value}
            });
          `;
              <Script
                strategy="lazyOnload"
                dangerouslySetInnerHTML={{ __html: script }}
              />;
              Swal.fire({
                title: 'NFT purchase successful!',
                text: 'To download original file, go to NFT details page.',
                html: `<div><div>To download original file, go to NFT details page.</div><br /><div class=thankyougems_title_separates_purchase><h5 class=thankyougemstitles_purchase>You Have Earned  - ${price > 50 ? price : 50}</h5><div class=cong_gems_small_img_purchase>
                <img src="/assets/gemssimple.svg" alt="Logo"/>
              </div></div><br /><div class=thankyougems_title_separates_purchase><h5 class=thankyougemstitles_purchase>Current Gems - ${price > 50 ? parseFloat(price) + parseFloat(updateGems) : parseFloat(50) + parseFloat(updateGems)}</h5><div class=cong_gems_small_img_purchase>
              <img src="/assets/gemssimple.svg" alt="Logo"/><br /></div>`,
                icon: 'success',
                showCancelButton: false,
                showCloseButton: true,
                confirmButtonColor: '#7557BB',
                cancelButtonColor: '#d33',
                confirmButtonText: 'NFT Details',
              }).then((result) => {
                if (router.pathname.includes('/token/')) {
                  router.reload(
                    '/token/' +
                      contract[contractAddress] +
                      ':' +
                      props.purchaseData.token
                  );
                } else {
                  router.push(
                    '/token/' +
                      contract[contractAddress] +
                      ':' +
                      props.purchaseData.token
                  );
                }
              });

            });
        } catch (err) {
          console.log(err,"err");
          props.onHide();
          setLoading(false);
          setChecked(false);
          hideTransactionPopup();
          let errorMessage = err?.data?.message ? err?.data?.message : '';
          let isInsufficient = errorMessage.search('insufficient');
          if (
            err.code == 'INSUFFICIENT_FUNDS' ||
            parseInt(isInsufficient) > 0
          ) {
            Swal.fire({
              icon: 'warning',
              title: 'Insufficient Funds!',
              text: 'Your transaction is failed due to insufficent funds!',
              timer: 2500,
            });
          } else {
            Swal.fire({
              icon: 'warning',
              // title: "Oops...",
              text: 'Your transaction is failed!',
              timer: 1500,
            });
          }
        }
      }
    }
  } else if (userState?.userProfileData?.wallet_type === 'custom') {
    let requiredBlockChain = currency === 'MATIC' ? 'polygon' : 'ethereum';
    let requiredChainID =
      currency === 'MATIC'
        ? process.env.NEXT_PUBLIC_POLYGON_CHAIN_ID
        : process.env.NEXT_PUBLIC_CHAIN_ID;
      if (checked === false) {
        setShowErrorMessage(true);
      } else {
        props.onHide();
        try {
          setShowErrorMessage(false);
          setLoading(true);

          if (blockchain === 'polygon' && currentBalance < (parseFloat(parseFloat(props.purchaseData.metadata.price) + 0.05))) {
            props.onHide();
            setLoading(false);
            // toast.error(`${response.data.message}`);
            Swal.fire({
              icon: "warning",
              // title: "Oops...",
              text: 'Insufficient Balance For Buy NFT',
              timer: 5000,
            });
            hideTransactionPopup();
          } else if (blockchain === 'ethereum' && currentBalance < (parseFloat(parseFloat(props.purchaseData.metadata.price) + 0.07))) {
            props.onHide();
            setLoading(false);
            // toast.error(`${response.data.message}`);
            Swal.fire({
              icon: "warning",
              // title: "Oops...",
              text: 'Insufficient Balance For Buy NFT',
              timer: 5000,
            });
            hideTransactionPopup();
          } else {
          showTransactionPopup('NFT purchase in progress', ' ', ' ');
          let price = props.purchaseData.metadata.price;
          const data = {
            to_address:userState?.userProfileData?.public_address,
            token:parseInt(props.purchaseData.token),
            value:parseFloat(price),
            blockchain:blockchain,
            // private_key: userState?.userProfileData?.private_key
            id: userState?.userProfileData?.id
          }
          const response = await buyNftTransaction(data)
          if (response.status === 200) {
                props.onHide();
                setLoading(false);
                setChecked(false);
                hideTransactionPopup();
              let logObj = {};
              logObj.operation = 'BOUGHT';
              logObj.token_ids = [props.purchaseData.token];
              logObj.tx_hash = response.data.data.transactionHash;
              logObj.blockchain = blockchain;
              let logresp = await logHistory(logObj);
              const script = `
            rdt('track', 'Purchase', {
              "currency": ${currency},
              "itemCount": 1,
              "transactionId": ${response.data.transactionHash},
              "value": ${parseFloat(price)}
            });
          `;
              <Script
                strategy="lazyOnload"
                dangerouslySetInnerHTML={{ __html: script }}
              />;
              Swal.fire({
                title: 'NFT purchase successful!',
                text: 'To download original file, go to NFT details page.',
                html: `<div><div>To download original file, go to NFT details page.</div><br />
                <div class=thankyougems_title_separates_purchase><h5 class=thankyougemstitles_purchase>Gas Fee  - ${parseFloat(response?.data?.data?.gas_price).toFixed(5)}</h5><div class=cong_gems_small_img_purchase>
              </div></div><br />
                <div class=thankyougems_title_separates_purchase><h5 class=thankyougemstitles_purchase>You Have Earned  - ${price > 50 ? price : 50}</h5><div class=cong_gems_small_img_purchase>
                <img src="/assets/gemssimple.svg" alt="Logo"/>
              </div></div><br /><div class=thankyougems_title_separates_purchase><h5 class=thankyougemstitles_purchase>Current Gems - ${price > 50 ? parseFloat(price) + parseFloat(updateGems) : parseFloat(50) + parseFloat(updateGems)}</h5><div class=cong_gems_small_img_purchase>
              <img src="/assets/gemssimple.svg" alt="Logo"/><br /></div>`,
                icon: 'success',
                showCancelButton: false,
                showCloseButton: true,
                confirmButtonColor: '#7557BB',
                cancelButtonColor: '#d33',
                confirmButtonText: 'NFT Details',
              }).then((result) => {
                if (router.pathname.includes('/token/')) {
                  router.reload(
                    '/token/' +
                      contract[contractAddress] +
                      ':' +
                      props.purchaseData.token
                  );
                } else {
                  router.push(
                    '/token/' +
                      contract[contractAddress] +
                      ':' +
                      props.purchaseData.token
                  );
                }
              });

          
          } else {
            props.onHide();
            setLoading(false);
            // toast.error(`${response.data.message}`);
            Swal.fire({
              icon: "warning",
              // title: "Oops...",
              text: `${response.data.message}`,
              timer: 5000,
            });
            hideTransactionPopup();
          }
        }
        
        } catch (err) {
          console.log(err,"err");
          props.onHide();
          setLoading(false);
          setChecked(false);
          hideTransactionPopup();
          let errorMessage = err?.data?.message ? err?.data?.message : '';
          let isInsufficient = errorMessage.search('insufficient');
          if (
            err.code == 'INSUFFICIENT_FUNDS' ||
            parseInt(isInsufficient) > 0
          ) {
            Swal.fire({
              icon: 'warning',
              title: 'Insufficient Funds!',
              text: 'Your transaction is failed due to insufficent funds!',
              timer: 2500,
            });
          } else {
            Swal.fire({
              icon: 'warning',
              // title: "Oops...",
              text: 'Your transaction is failed!',
              timer: 1500,
            });
          }
        }
      }
  }
  };
  return (
    <Model
      visible={props.visible}
      title={'Purchase'}
      className={`${styles.wallet_model}`}
      onHide={() => {
        props.onHide();
        setChecked(false);
      }}
    >
      {props && props.purchaseData && props.purchaseData.metadata ? (
        <>
          <div className={`${styles.chececkedButtonForm}`}>
            <input
              checked={checked}
              onChange={(e) => {
                setChecked(!checked);
                if (e.target.checked === true) {
                  setShowErrorMessage(false);
                } else {
                  setShowErrorMessage(true);
                }
              }}
              type="checkbox"
              style={{ appearance: 'auto' }}
            />{' '}
            <p>
              By performing this action, you agree to our
              <a
                style={{ color: '#7558BB' }}
                href={`${process.env.NEXT_PUBLIC_WEB_URL}/terms-of-services`}
              >
                <b> Terms of Service</b>
              </a>{' '}
              and{' '}
              <a
                style={{ color: '#7558BB' }}
                href={`${process.env.NEXT_PUBLIC_WEB_URL}/privacy-policy`}
              >
                <b>Privacy Policy.</b>
              </a>
              {showErrorMsg && (
                <small className={`text-danger`}>
                  <br />
                  {`Please agree with our terms of service & privacy policy`}
                </small>
              )}
            </p>
          </div>
          <strong>{props.purchaseData.title}</strong>.
          <br />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={styles.text}>Your Balance</div>
            <div style={{ fontWeight: 500 }} className={styles.text}>
              {userState?.userProfileData?.wallet_type === "custom" ?
               (parseFloat(currentBalance).toFixed(4)) + 'MATIC' + ' ' + parseFloat(walletDetail).toFixed(4) + 'ETH' :
               (parseFloat(currentBalance).toFixed(4)) + (currency)
            }
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={styles.text}>Price</div>
            <div style={{ fontWeight: 500 }} className={styles.text}>
              {parseFloat(nftPrice).toFixed(4)} {currency}
            </div>
          </div>
          {userState?.userProfileData?.wallet_type === 'custom' &&
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={styles.text}>Approx. Gas Fees</div>
            <div style={{ fontWeight: 500 }} className={styles.text}>
            {blockchain === 'ethereum' ? '0.007 ETH' : '0.05 MATIC'}
            </div>
          </div>
          }
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={styles.text}>Service Fee</div>
            <div style={{ fontWeight: 500 }} className={styles.text}>
              {parseFloat(serviceFee).toFixed(4)} {currency}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={styles.text}>Total</div>
            <div style={{ fontWeight: 500 }} className={styles.text}>
              {parseFloat(totalPrice).toFixed(4)} {currency}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={styles.text}>USD</div>
            <div style={{ fontWeight: 500 }} className={styles.text}>
              {parseFloat(getUSDPrice(totalPrice)).toFixed(2)}$
            </div>
          </div>
          <br />
          {/* // {userState?.userProfileData?.wallet_type === 'custom' &&
          // <span className={`${styles.gasfeetext}`}>Please maintain your Wallet balance. Max Process fees will charge 0.5%</span>
          // } */}
          {!onloading ? (
            <div>
              <Button
                className={`mr-2`}
                type="submit"
                onClick={() => {
                  onPurchase();
                }}
                // disabled={checked === true ? false : true}
              >
                Purchase
              </Button>
              <Button
                className={``}
                varient={'secondary_nooutline'}
                onClick={() => {
                  props.onHide();
                  // toast.error("Purchase cancelled");
                  Swal.fire({
                    icon: 'warning',
                    // title: "Oops...",
                    text: 'Purchase cancelled',
                    timer: 1500,
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Loader
                type="ThreeDots"
                color="#7557BB"
                height={50}
                width={50}
                timeout={3000000} //3 secs
              />
            </div>
          )}
        </>
      ) : null}
    </Model>
  );
};

export default PurchaseNftModel;
